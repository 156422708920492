require('./bootstrap')


window.Vue = require("vue");
window.Fire = new Vue();

// import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// ES6 Modules or TypeScript
import Swal from "sweetalert2";
window.Swal = Swal;
require("./componentWelcome");
const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

//vform
import { Form, HasError, AlertError } from "vform";
window.Form = Form;
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

// momentjs Суулгаж ажиллууллаа.
import moment from "moment";

Vue.filter("dateYearMonthDay", function (created) {
    // moment().format();
    return moment(created).format("MM/DD/YYYY");
});
Vue.filter("dateYear", function (created) {
    // moment().format();
    return moment(created).format("YYYY");
});
Vue.filter("dateMonth", function (created) {
    // moment().format();
    return moment(created).format("MM");
});
Vue.filter("dateFullYear", function (created) {
    // moment().format();
    return moment(created).format("ll");
});
moment.locale("mn");
import Vue from "vue";
const app = new Vue({
    el: "#app",
});
