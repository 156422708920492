<template>
  <div class="row my-5">
    <div class="col-lg-12">
      <h4>Сэтгэл ханамжийн судалгаа</h4>
      <div v-if="lsSuccess">
        <b-alert show variant="success">
          <h4 class="alert-heading">
            <b-icon icon="check2-circle" aria-hidden="true"></b-icon>
            Амжилттай!
          </h4>
          <p>Сэтгэл ханамжийн судалгааг амжилттай өглөө.</p>
          <hr />
          <p class="mb-0">Танд маш их баярлалаа. Өдрийг сайхан өнгөрүүлээрэй.</p>
        </b-alert>
        <div class="card" ref="printContent" id="section-to-print">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <b-overlay
                  :show="isLoadMethod"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <div class="d-flex justify-content-between">
                    {{ timestamp }}
                    <p class="text-right">
                      Судалгаанд хамрагдсан: <b>{{ form.answers[0].answer }}</b>
                    </p>
                  </div>
                  <h3 class="text-center" v-if="selectedMethod">
                    {{ selectedMethod.name }}
                  </h3>
                  <div class="d-flex justify-content-center" v-if="!isLoadMethod && selectedMethod">
                    <apexchart
                      v-if="chartType == 'pie'"
                      width="600"
                      type="donut"
                      :options="optionsPieChart"
                      :series="seriesChart"
                    ></apexchart>
                    <apexchart
                      v-else-if="chartType == 'bar' && resultData.length > 1"
                      type="bar"
                      width="600"
                      :options="optionsBarChart"
                      :series="seriesBarChart"
                    ></apexchart>
                    <apexchart
                      v-else-if="chartType == 'radar'"
                      type="radar"
                      width="800"
                      :options="optionsRadarChart"
                      :series="seriesBarChart"
                    ></apexchart>
                    <apexchart
                      v-else-if="chartType == 'area'"
                      type="area"
                      width="1300"
                      height="400"
                      :options="optionsAreaChart"
                      :series="seriesAreaChart"
                    ></apexchart>
                  </div>
                  <p v-if="selectedMethod" v-html="selectedMethod.description"></p>
                  <div class="row" v-if="resultData.length > 3">
                    <div
                      class="col-lg-3"
                      v-for="(data, index) in resultData"
                      :key="data.id"
                    >
                      <b>{{ index + 1 }}. {{ data.name }}</b>
                      <span v-html="data.result" class="text-justify"></span>
                      <span v-html="data.description" class="text-justify"></span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(data, index) in resultData" :key="data.id">
                      <b>{{ index + 1 }}. {{ data.name }}</b>
                      <span v-html="data.result" class="text-justify"></span>
                      <span v-html="data.description" class="text-justify"></span>
                    </div>
                  </div>
                  <div
                    v-for="(answer, index) in resultAnswers"
                    :key="answer.id"
                    class="mb-3"
                  >
                    <i class="text-bold">{{ index + 1 }}. {{ answer.label }}</i
                    ><br />
                    <span class="text-justify ml-3">{{ answer.answer }}</span>
                  </div>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="lsSatisfaction">
        <div class="direct-chat-msg">
          <div class="direct-chat-infos clearfix">
            <span class="direct-chat-timestamp float-left" v-if="satisfactionShow.owner">
              <!-- <img
                v-if="satisfactionShow.owner.profile_photo_url"
                class="direct-chat-img mr-2"
                :src="satisfactionShow.owner.profile_photo_url"
                alt="Зураг"
              />
              <img
                v-else
                src="/images/users/user.png"
                class="direct-chat-img mr-2"
                alt="user-avatar"
              />
              <b>{{ satisfactionShow.owner.teacher_name }}</b>
              <br /> -->
              <b>{{ satisfactionShow.title }}</b>
            </span>
            <span class="direct-chat-timestamp float-right"
              ><b>Огноо: </b>{{ satisfactionShow.created_at | dateYearMonthDay }} </span
            ><br />
          </div>
          <div class="direct-chat-text light ml-0">
            {{ satisfactionShow.description }}
          </div>
          <b-alert
            v-if="satisfactionShow.type == 'Сэдэвт суурилсан'"
            class="mt-1"
            show
            variant="light"
          >
            <p class="alert-heading mb-1">Судалгааны сэдэв</p>
            <b-list-group>
              <b-list-group-item
                v-for="model in satisfactionShow.models"
                :key="model.id"
                class="d-flex justify-content-between py-0"
              >
                <span>
                  {{ model.title }} |
                  {{ model.modelData }}
                </span>
              </b-list-group-item>
            </b-list-group>
            <small class="mb-0 text-muted font-italic">
              Дээрх сэдвүүд дээр судалгааны асуумжийг бөглөнө үү.
            </small>
          </b-alert>
        </div>
        <b-overlay :show="satisfactionIsLoaded" rounded="sm">
          <form @submit.prevent="sendSatisfaction()">
            <div
              class="form-group"
              v-for="(satInput, index) in satisfactionInputs"
              :key="satInput.id"
            >
              <label
                >{{ index + 1 + ". " + satInput.label
                }}{{ satInput.isRequired == 1 ? "*" : "" }}</label
              >
              <b-form-input
                v-if="satInput.type == 'Text' && satInput.isMultiple == 0"
                :type="satInput.isNumberOrText == 1 ? 'text' : 'number'"
                placeholder="Хариултаа оруулна уу"
                :required="satInput.isRequired == 1 ? true : false"
                @input="setPushAnswer(satInput.id, $event, satInput.isRequired)"
              />
              <b-form-textarea
                v-else-if="satInput.type == 'Text' && satInput.isMultiple == 1"
                placeholder="Хариултаа оруулна уу..."
                rows="3"
                max-rows="6"
                :required="satInput.isRequired == 1 ? true : false"
                @input="setPushAnswer(satInput.id, $event, satInput.isRequired)"
              ></b-form-textarea>
              <multiselect
                :class="{
                  'is-invalid': isSubmited == true && isError(satInput.id) == true,
                }"
                v-model="formSelect['value' + satInput.id]"
                deselect-label="Хасах"
                select-label="Сонгох"
                selected-label="Сонгогдсон"
                track-by="value"
                placeholder="Хариултаа сонгох"
                :options="getOption(satInput.value)"
                label="value"
                :searchable="false"
                :allow-empty="false"
                :close-on-select="satInput.isMultiple == 1 ? false : true"
                v-else-if="satInput.type == 'Choice'"
                :multiple="satInput.isMultiple == 1 ? true : false"
                @input="
                  setPushAnswer(
                    satInput.id,
                    $event,
                    satInput.isRequired,
                    satInput.type,
                    satInput.isMultiple
                  )
                "
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.value }}
                </template>
              </multiselect>
              <b-form-datepicker
                v-else-if="satInput.type == 'Date'"
                aria-required="true"
                :class="{
                  'is-invalid': isSubmited == true && isError(satInput.id) == true,
                }"
                @input="setPushAnswer(satInput.id, $event, satInput.isRequired)"
                size="sm"
                placeholder="Огноо сонгоно уу."
              ></b-form-datepicker>
              <b-form-spinbutton
                v-else-if="satInput.type == 'Rate' && satInput.symbol == 'Number'"
                id="sb-wrap"
                wrap
                min="1"
                :max="satInput.value"
                placeholder="--"
                :class="{
                  'is-invalid': isSubmited == true && isError(satInput.id) == true,
                }"
                @change="setPushAnswer(satInput.id, $event, satInput.isRequired)"
              ></b-form-spinbutton>
              <b-form-rating
                v-else-if="satInput.type == 'Rate'"
                :icon-empty="satInput.symbol"
                :icon-half="satInput.symbol + ' - half'"
                :icon-full="satInput.symbol + '-fill'"
                icon-clear="slash-circle"
                show-clear
                :stars="satInput.value"
                color="#ff8800"
                variant="info"
                :class="{
                  'is-invalid': isSubmited == true && isError(satInput.id) == true,
                }"
                @change="setPushAnswer(satInput.id, $event, satInput.isRequired)"
              ></b-form-rating>
              <small
                class="form-text text-danger"
                v-if="isSubmited == true && isError(satInput.id) == true"
              >
                <b-icon variant="danger" icon="info-circle" aria-hidden="true"></b-icon>
                Энэ талбарыг бөглөнө үү!
              </small>
              <small class="form-text text-muted">{{ satInput.description }}</small>
            </div>
            <b-overlay
              :show="lsClicked"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <button type="submit" class="btn btn-info">Илгээх</button>
            </b-overlay>
          </form>
        </b-overlay>
      </div>
      <div v-else>
        <b-alert show variant="warning">
          <h4 class="alert-heading">
            <b-icon icon="emoji-frown" aria-hidden="true"></b-icon>
            Алдаа!
          </h4>
          <p>Сэтгэл ханамжийн судалгаа олдсонгүй.</p>
          <hr />
          <p class="mb-0">
            Судалгааны хаяг буруу байгаа учир сургуулиас лавлаж асууна уу.
          </p>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      satisfactionShow: new Form({
        id: "",
        title: "",
        type: "",
        models: [],
        description: "",
        owner: null,
        userType: "",
        created_at: "",
      }),
      form: new Form({ satisfaction_id: null, answers: [] }),
      satisfactionIsLoaded: true,
      satisfactionInputs: [],
      isSubmited: false,
      errorInputs: [],
      formSelect: new Form({}),
      lsClicked: false,
      lsSuccess: false,
      lsSatisfaction: false,
      isLoadMethod: false,
      selectedMethod: null,
      methodOptions: [],
      resultData: [],
      resultAnswers: [],
      schoolInfo: null,
      timestamp: "",
      chartType: "",
      seriesChart: [],
      seriesBarChart: [
        {
          name: "Үр дүн",
          data: [],
        },
      ],
      seriesAreaChart: [],
      optionsPieChart: {
        labels: [],
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              labels: {
                show: true,
                name: { show: true },
                value: { show: true },
                total: {
                  show: true,
                  label: "Нийт",
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
      optionsBarChart: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "center", // top, center, bottom`
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      optionsRadarChart: {
        chart: {
          height: 350,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#8eceff", "#cbe6fa"],
              },
            },
          },
        },
        colors: ["#FF4560"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: [],
        },
      },
      optionsAreaChart: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "string",
          categories: [],
          showDuplicates: false,
          offsetX: 60,
          labels: {
            show: true,
            style: {
              fontSize: "8px",
              fontWeight: "bold",
            },
          },
        },
      },
      colors: [
        "#008ffb",
        "#00e396",
        "#feb019",
        "#ff4560",
        "#701add",
        "#c90076",
        "#ebdb5e",
        "#3b185a",
        "#134f5c",
        "#ffde14",
      ],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    //#region Sudalgaa
    showSatisfaction() {
      const url = window.location.href;
      const parts = url.split("/");
      let satId = parseInt(parts.pop(), 10);
      if (satId) {
        this.lsClicked = false;
        this.lsSuccess = false;
        this.lsSatisfaction = false;
        this.isSubmited = false;
        this.errorInputs = [];
        this.satisfactionIsLoaded = true;
        this.satisfactionShow.reset();
        axios
          .get("/getSatisfaction/" + satId)
          .then((response) => {
            const sat = response.data.satisfaction;
            this.selectedMethod = sat.method;
            if (sat != null) {
              this.lsSatisfaction = true;
              this.satisfactionShow.fill(sat);
              this.form.reset();
              this.form.satisfaction_id = sat.id;
              this.satisfactionInputs = response.data.satisfactionInputs;
              this.satisfactionInputs.forEach((input) => {
                this.form.answers.push({
                  input_id: input.id,
                  answer: "",
                  isRequired: input.isRequired,
                });
              });
              this.satisfactionIsLoaded = false;
            } else this.lsSatisfaction = false;
          })
          .catch((errors) => {
            //   console.log(errors);
          });
      } else Swal.fire("Сэтгэл ханамжийн судалгаа олдсонгүй!", "", "warning");
    },
    getOption(optionValue) {
      let optionList = [];
      let option = [];
      optionList = optionValue.split("<^>");
      optionList.forEach((val) => {
        if (val != "") {
          option.push({
            value: val,
          });
        }
      });
      return option;
    },
    setPushAnswer(satInputId, value, isRequired, type, isMultiple) {
      this.form.answers = this.form.answers.filter((i) => i.input_id !== satInputId);
      if (type == "Choice" && isMultiple == 1) {
        let valueStr = "<^>";
        value.forEach((option) => {
          valueStr = valueStr + option.value + "<^>";
        });
        value = valueStr;
      } else if (type == "Choice" && isMultiple == 0) value = value.value;

      this.form.answers.push({
        input_id: satInputId,
        answer: value,
        isRequired: isRequired,
      });
    },
    isError(inputId) {
      let error = this.errorInputs.filter((i) => i.input_id == inputId);
      if (error.length > 0) return true;
      else return false;
    },
    sendSatisfaction() {
      this.isSubmited = true;
      this.lsClicked = true;
      this.errorInputs = [];
      this.form.answers.forEach((answer) => {
        if (answer.answer == "" && answer.isRequired == 1) {
          this.errorInputs.push(answer);
        }
      });
      if (this.errorInputs.length > 0) {
        this.lsClicked = false;
        this.lsSuccess = false;
        this.$toastr.w("Шаардлагатай талбарыг бөглөнө үү!", "Анхаар");
      } else {
        this.form
          .post("/sendPublicSatisfaction")
          .then((res) => {
            Swal.fire({
              position: "top-end",
              toast: true,
              timer: 5000,
              icon: "success",
              timerProgressBar: true,
              title: "Амжилттай",
              text: "Судалгаа амжилттай илгээлэй.",
              showConfirmButton: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            this.getMethodResult(res.data.userId);
            this.isSubmited = false;
            this.lsClicked = false;
            this.lsSuccess = true;
          })
          .catch(() => {
            this.lsClicked = false;
            this.lsSuccess = false;
          });
      }
    },
    //#endregion
    //#region Method
    getMethodResult(userId) {
      this.seriesChart = [];
      this.seriesBarChart = [];
      this.seriesBarChart = [
        {
          name: "Үр дүн",
          data: [],
        },
      ];
      this.isLoadMethod = true;
      this.optionsPieChart.labels = [];
      this.optionsBarChart.xaxis.categories = [];
      axios
        .post("/getMethodResult", {
          methodId: this.selectedMethod ? this.selectedMethod.id : null,
          satId: this.satisfactionShow.id,
          userId: userId,
        })
        .then((response) => {
          if (this.selectedMethod) {
            this.chartType = response.data.chartType;
            if (this.chartType == "pie") {
              this.seriesChart = response.data.chartSeries;
              this.optionsPieChart.labels = response.data.chartLabels;
            } else if (this.chartType == "bar") {
              this.optionsBarChart.colors = this.getRandomColor(this.colors);
              this.seriesBarChart[0].data = response.data.chartSeries;
              this.optionsBarChart.xaxis.categories = response.data.chartLabels;
            } else if (this.chartType == "radar") {
              this.seriesBarChart[0].data = response.data.chartSeries;
              this.optionsRadarChart.xaxis.categories = response.data.chartLabels;
            } else if (this.chartType == "area") {
              this.seriesAreaChart = response.data.chartSeries;
              this.optionsAreaChart.xaxis.categories = response.data.chartLabels;
            }
          }
          this.resultData = response.data.resultData;
          this.resultAnswers = response.data.resultAnswers;
          this.isLoadMethod = false;
        })
        .catch((errors) => {
          this.isLoadMethod = false;
          this.$toastr.w("Алдаа гарлаа." + errors, "Анхаар");
          return;
        });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      const randomColor = this.colors[randomIndex];
      return randomColor;
    },
    //#endregion
  },
  created() {
    setInterval(this.getNow, 1000);
    this.showSatisfaction();
  },
};
</script>

<style scoped>
.direct-chat-text::after,
.direct-chat-text::before {
  border: solid transparent;
  border-right-color: #f3f3f3;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 15px;
  width: 0;
}
.direct-chat-text {
  border-radius: 0.3rem;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  color: #444;
  margin: 5px 0 0 50px;
  padding: 5px 10px;
  position: relative;
}
</style>
