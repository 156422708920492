<template>
    <div class="pt-5" id="sidebar-results">
        <div class="text-center">
            <img height="80" :alt="showMatch.name" :src="showMatch.cover" />
            <p>{{ showMatch.name }}</p>
        </div>
        <div class="scoreCard my-3 mx-3">
            <div class="row">
                <div class="col-lg-12 table-responsive">
                    <b-skeleton-table v-if="loadResult" class="mt-3" :rows="3" :columns="3"
                        :table-props="{ bordered: false, striped: false }"></b-skeleton-table>
                    <table v-else class="table text-wrap table-hover">
                        <thead>
                            <tr role="row">
                                <th>#</th>
                                <th>Баг</th>
                                <th>Оноо</th>
                                <th v-if="showMatch.is_reverse == 0">Нийт {{ showMatch.max_point }}</th>
                                <th v-if="showMatch.is_reverse == 0">Хувь</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(teamResult, index) in matchResults" :key="teamResult.id">
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ index + 1 }}
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    <b-avatar v-if="teamResult.logo != null" title="Зураг" rounded="sm"
                                        :src="teamResult.logo" icon="people-fill" size="sm" variant="light"></b-avatar>
                                    <b-avatar v-else-if="teamResult.department" variant="light" icon="people-fill"
                                        rounded="lg" size="sm"></b-avatar>
                                    <b-avatar v-else-if="teamResult.my_class" variant="light" icon="people-fill"
                                        rounded="lg" size="sm"></b-avatar>
                                    <span v-if="showMatch.isTeam == 1">{{ teamResult.name }}</span>
                                    <span v-else-if="teamResult.department">{{
                                        teamResult.department.department_name
                                        }}</span>
                                    <span v-else-if="teamResult.my_class">{{
                                        teamResult.my_class.full_name
                                        }}</span>
                                </td>
                                <td width="80%" class="dtr-control sorting_1" tabindex="0">
                                    <b-progress :max="teamResult.maxTotal" show-value :animated="true">
                                        <b-progress-bar v-for="(section, index) in teamResult.sections"
                                            :key="section.id" :value="section.total"
                                            :variant="index == 1 ? 'primary' : 'success'"
                                            :style="`background-color: ${progressColors[index]} !important;`">
                                            <span>
                                                <strong>{{ section.total }} / {{ section.max_point }}</strong>
                                            </span>
                                        </b-progress-bar>
                                    </b-progress>
                                </td>
                                <td v-if="showMatch.is_reverse == 0">
                                    {{ teamResult.point.toFixed(2) }}
                                </td>
                                <td v-if="showMatch.is_reverse == 0">
                                    {{ ((teamResult.point * 100) / showMatch.max_point).toFixed(2) }}%
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h4 class="text-center">Төрөл тус бүрийн оноо</h4>
            <div class="row">
                <div v-for="(section, index) in matchSectionItems" :key="section.id" :class="[
                    'text-center mt-3 col-md-6 col-sm-12 pb-3',
                    matchSectionItems.length >= 4
                        ? 'col-lg-3'
                        : matchSectionItems.length == 3
                            ? 'col-lg-4'
                            : matchSectionItems.length == 2
                                ? 'col-lg-6'
                                : 'col-lg-12',
                ]">
                    <b-badge pill class="p-2 text-sm" variant="dark" role="button"
                        :style="`background-color: ${progressColors[index]} !important;`"
                        @click="showSectionResult(section)">
                        <b-avatar :title="section.name" :src="section.logo" variant="light"></b-avatar>
                        {{ section.name }}
                        <b-badge v-if="showMatch.is_reverse == 0" variant="light" pill>Нийт: {{ section.max_point }}
                        </b-badge>
                    </b-badge>
                    <b-list-group @click="showSectionResult(section)" class="mt-3"
                        :style="`background-color: ${progressColors[index]} !important;border-radius:10px;`">
                        <b-list-group-item v-if="section.match_items.length > 0" class="align-items-center py-1 bg-dark"
                            :style="`background-color: ${progressColors[index]} !important`">
                            Чадвар тус бүрийн оноо
                        </b-list-group-item>
                        <b-list-group-item v-for="item in section.match_items" :key="item.id"
                            class="d-flex justify-content-between align-items-center py-2 bg-dark"
                            :style="`background-color: ${progressColors[index]} !important`">
                            {{ item.name }}
                            <b-badge variant="light" pill v-if="item.my_items_sum_point">
                                {{ item.my_items_sum_point.toFixed(2) }}
                                {{ section.measure ? section.measure.short_name : " оноо" }}</b-badge>
                        </b-list-group-item>
                        <b-list-group-item class="py-2 bg-dark"
                            :style="`background-color: ${progressColors[index]} !important`">
                            <b-button variant="outline-light" size="xs" @click="showSectionResult(section)">
                                Дэлгэрэнгүй
                            </b-button>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </div>
            <div v-show="showQr" class="qr-code text-center">
                <span>
                    <b class="text-yellow">SCAN ME</b>
                    <qr-code class="qrCode" :text="qrShow.url"></qr-code>
                </span>
            </div>
        </div>
        <div v-if="showMatch" class="d-flex bg-light text-light align-items-center px-3 py-2">
            <strong class="mr-auto">{{ showMatch.name }}</strong>
            <b-button variant="light" size="sm" @click="showQr = !showQr" class="float-right mr-2">
                <i class="fas fa-qrcode"></i></b-button>
            <span class="float-right mr-2"><b>Тэмцээний огноо: </b>{{ showMatch.date | dateYearMonthDay }}
            </span>
            <b-overlay :show="loadResult" rounded opacity="0.6" spinner-small spinner-variant="primary"
                class="d-inline-block">
                <b-button variant="primary" size="sm" @click="getMatchResult()" class="mr-2">
                    <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                    Шинэчлэх
                </b-button>
            </b-overlay>
        </div>
        <b-modal size="xl" id="modal-user-results" title="Оролцогчдын авсан оноо" body-bg-variant="light"
            class="modal fade" hide-footer>
            <div class="modal-body">
                <div class="direct-chat-msg" v-if="selectedSection">
                    <div class="direct-chat-infos clearfix">
                        <span class="direct-chat-name float-left"><b-avatar v-if="selectedSection.logo != null"
                                title="Зураг" rounded="sm" :src="selectedSection.logo" size="sm"></b-avatar>
                            {{ selectedSection.name }}
                        </span>
                        <span class="float-right" v-if="showMatch.is_reverse == 0">
                            Боломжит дээд оноо:
                            <b>{{ selectedSection.max_point }}</b>
                        </span>
                        <span class="float-right" v-else>
                            <b>Бага онооны систем</b>
                        </span>
                    </div>
                </div>
                <div class="row mt-2" v-if="selectedSection">
                    <div v-if="loadTabData" class="col-lg-12">
                        <b-skeleton-table class="mt-5" :rows="8" :columns="selectedSection.match_items.length + 1"
                            :table-props="{ bordered: false, striped: false }"></b-skeleton-table>
                    </div>
                    <div v-else class="col-lg-12">
                        <div class="card mt-1" v-for="(team, index) in matchSectionTeams" :key="team.id">
                            <div class="card-header team-color-result">
                                <b-row align-v="start">
                                    <b-col><b-badge variant="light" :class="index == 0
                                            ? 'gold'
                                            : index == 1
                                                ? 'silver'
                                                : index == 2
                                                    ? 'bronze'
                                                    : ''
                                        ">
                                            {{ index + 1 }}
                                        </b-badge></b-col>
                                    <b-col>
                                        <h3 class="card-title">
                                            <b-avatar v-if="team.logo" title="Зураг" rounded="sm" :src="team.logo"
                                                size="sm" variant="light" :class="index == 0
                                                        ? 'gold'
                                                        : index == 1
                                                            ? 'silver'
                                                            : index == 2
                                                                ? 'bronze'
                                                                : ''
                                                    "></b-avatar>
                                            <b-avatar v-else title="Зураг" rounded="sm" :text="team.short_name"
                                                size="sm" variant="light" :class="index == 0
                                                        ? 'gold'
                                                        : index == 1
                                                            ? 'silver'
                                                            : index == 2
                                                                ? 'bronze'
                                                                : ''
                                                    "></b-avatar>
                                            {{ team.name }}
                                        </h3>
                                    </b-col>
                                    <b-col>
                                        <div class="float-right">
                                            Багийн оноо:
                                            <b-badge variant="light" :class="index == 0
                                                    ? 'gold'
                                                    : index == 1
                                                        ? 'silver'
                                                        : index == 2
                                                            ? 'bronze'
                                                            : ''
                                                ">
                                                <b class="text-success">{{ team.point.total }}</b>
                                            </b-badge>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="card-body table-responsive" v-if="team.users.length > 0">
                                <table class="table text-wrap table-striped table-hover">
                                    <thead>
                                        <tr role="row">
                                            <th>#</th>
                                            <th>Нэр</th>
                                            <th>Цахим шуудан</th>
                                            <th v-for="item in selectedSection.match_items" :key="item.id">
                                                {{ item.name }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(sectionUser, index) in team.users" :key="sectionUser.id">
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ sectionUser.name }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0">
                                                {{ sectionUser.email }}
                                            </td>
                                            <td class="dtr-control sorting_1" tabindex="0"
                                                v-for="item in sectionUser.items" :key="item.id">
                                                <span>
                                                    <b v-if="
                                                        item.my_items.find((i) => i.user_id == sectionUser.user_id)
                                                    ">
                                                        {{
                                                            item.my_items.find((i) => i.user_id == sectionUser.user_id)
                                                        .point
                                                        }}
                                                    </b>
                                                    <b v-else> 0 </b>
                                                    {{
                                                        selectedSection.measure
                                                            ? selectedSection.measure.name
                                                    : " оноо"
                                                    }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- /.card-body -->
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            matchId: 0,
            qrShow: { name: "", url: "" },
            loadResult: false,
            showQr: false,
            showMatch: {},
            matchResults: [],
            matchSectionItems: [],
            progressColors: [
                "#775DD0",
                "#feb019",
                "#E91E63",
                "#0c705c",
                "#139367",
                "#2E93fA",
                "#FA2EC9",
                "#249D9D",
                "#970E8E",
                "#D8C508",
                "#0c701b",
                "#0454ff",
            ],
            selectedSection: {},
            selectedTeam: {},
            loadTabData: false,
            matchSectionTeams: [],
        };
    },
    computed: {
        baseURL() {
            return (
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port ? ":" + window.location.port : "")
            );
        },
    },
    mounted() {
        // Call the function every 5 minutes (300000 milliseconds)
        if (this.matchId != 0) this.interval = setInterval(this.reloadResult, 300000);
    },
    beforeDestroy() {
        // Clear the interval when the component is destroyed
        clearInterval(this.interval);
    },
    methods: {
        reloadResult() {
            axios
                .get("/getPublicResult/" + this.matchId)
                .then((response) => {
                    if (this.showMatch.is_reverse == 1)
                        this.matchResults = _.orderBy(response.data.matchResults, "point", "asc");
                    else this.matchResults = _.orderBy(response.data.matchResults, "point", "desc");
                    this.matchSectionItems = response.data.matchSectionItems;
                    this.qrShow.url = this.baseURL + "/matchResult/" + this.matchId;
                })
                .catch((error) => {
                    //   console.log(error);
                });
        },
        getMatchResult() {
            const url = window.location.href;
            const parts = url.split("/");
            this.matchId = parseInt(parts.pop(), 10);
            if (this.matchId != 0) {
                this.loadResult = true;
                axios
                    .get("/getPublicResult/" + this.matchId)
                    .then((response) => {
                        if (response.data.error) {
                            Swal.fire("Тэмцээн олдсонгүй!", "", "warning");
                            this.loadResult = false;
                            return;
                        } else {
                            this.showMatch = response.data.match;
                            if (this.showMatch.is_reverse == 1)
                                this.matchResults = _.orderBy(response.data.matchResults, "point", "asc");
                            else
                                this.matchResults = _.orderBy(
                                    response.data.matchResults,
                                    "point",
                                    "desc"
                                );
                            this.matchSectionItems = response.data.matchSectionItems;
                            this.qrShow.url = this.baseURL + "/matchResult/" + this.matchId;
                            this.loadResult = false;
                        }
                    })
                    .catch((error) => {
                        //   console.log(error);
                    });
            } else Swal.fire("Тэмцээн олдсонгүй!", "", "warning");
        },
        showSectionResult(section) {
            this.selectedSection = section;
            axios
                .get("/getTeams/" + this.showMatch.id)
                .then((response) => {
                    this.selectedTeam = response.data.matchTeams;
                    this.getScoreData();
                    this.$bvModal.show("modal-user-results");
                })
                .catch((error) => {
                    //   console.log(error);
                });
        },
        getScoreData() {
            this.loadTabData = true;
            let teams = [];
            this.selectedTeam.forEach((team) => {
                teams.push(team.team_id);
            });
            axios
                .post("/getScoreData/" + this.showMatch.id, {
                    selectedSection: this.selectedSection.id,
                    selectedTeams: teams,
                })
                .then((response) => {
                    if (this.showMatch.is_reverse == 1)
                        this.matchSectionTeams = _.orderBy(
                            response.data.matchSectionTeams,
                            "total",
                            "asc"
                        );
                    else
                        this.matchSectionTeams = _.orderBy(
                            response.data.matchSectionTeams,
                            "total",
                            "desc"
                        );
                    this.loadTabData = false;
                })
                .catch((error) => {
                    this.loadTabData = false;
                    //   console.log(error);
                });
        },
    },
    created() {
        this.getMatchResult();
    },
};
</script>

<style scoped>
.progress {
    border-radius: 10px;
    background-color: #e9ecef54;
}

.table-avatar {
    width: 40px !important;
    height: 40px;
    object-fit: cover;
}

.option__image {
    height: 25px;
}

.progress-bar {
    border: #e9ecef;
    border-width: 0 0 0 0;
    border-style: solid;
    color: #fff !important;
}

.scoreCard {
    position: relative;
    border: solid 1px #fff;
    border-radius: 15px;
    padding: 15px;
    min-height: 75vh;
}

.table td,
.table th {
    border-top: none;
}

.b-sidebar>.b-sidebar-body {
    overflow-x: hidden;
}

.table-hover tbody tr:hover {
    color: #feb019;
}

.gold {
    background-color: gold !important;
    color: white;
    font-size: 15px;
}

.silver {
    background-color: silver !important;
    color: white;
    font-size: 14px;
}

.bronze {
    background-color: #ce8946 !important;
    color: white;
    font-size: 13px;
}

.qr-code {
    position: absolute;
    bottom: 0;
    right: 15px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 10px;
}

.qrCode>img {
    height: 50px;
}
</style>
